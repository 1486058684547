import { useCallback, useEffect, useRef } from 'react'
import { useDrop } from 'react-dnd'
import { toast } from 'react-toastify'

import { DateTime } from 'luxon'
import QRCode from 'qrcode'

import { pdf } from '@react-pdf/renderer'
import { mappedHours } from 'constants/calendar'
import useIsMobile from 'hooks/useIsMobile'
import { ApppointmentDocument } from 'pages/appointments/pdf'
import appointmentService from 'services/appointment-service'
import eventService from 'services/event-service'
import addJobToFirebase from '../../../firebase/utility'

interface MiniSlotProps {
	time: string
	isHalfHour?: boolean
	showLabel?: boolean
	onUpdate: () => void
}

const MiniSlot = ({ time, isHalfHour, showLabel = false, onUpdate }: MiniSlotProps) => {
	const isMobile = useIsMobile()
	const timeRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (timeRef) {
			const element = timeRef.current
			if (element) {
				const offset = isMobile ? 240 : 240
				const elementPosition = element.getBoundingClientRect().top
				const offsetPosition = elementPosition + window.pageYOffset - offset
				window.scrollTo({
					top: offsetPosition,
					behavior: 'smooth'
				})
			}
		}
	}, [timeRef, isMobile])

	const generateInvoice = (appointment: Appointment) => {
		const sendPdf = async () => {
			const qrCodeURL = await QRCode.toDataURL(
				`https://pro.stylingcorner.ch/appointment-details/${appointment._id}`,
				{
					margin: 0,
					width: 80
				}
			)
			const blob = await pdf(
				<ApppointmentDocument qrCodeURL={qrCodeURL} appointment={appointment} />
			).toBlob()
			const formData = new FormData()
			const fileName = `${appointment.id_client.fname + '_' + DateTime.fromMillis(appointment.from).toFormat('dd.LL.yyyy')}_appointment.pdf`
			formData.append('file', blob, `${fileName}.pdf`)
			formData.append('id_client', appointment.id_client._id)
			formData.append('id_company', appointment.id_company._id)
			await appointmentService.sendEmail(appointment._id, formData)
			onUpdate()
		}
		sendPdf()
	}

	const updateTime = useCallback(
		(event: any) => {
			const { id, totalMinutes, startDateTime, ...data } = event

			const start = DateTime.fromISO(startDateTime)
			const slot = DateTime.fromFormat(isHalfHour ? time : mappedHours[time], 'h:mma')
			const hours = slot.hour
			const minutes = slot.minute
			const from = start.set({ hour: hours, minute: minutes })
			const to = from.plus({ minute: totalMinutes })

			if (event.type === 'appointment') {
				appointmentService
					.updateAppointmentSlots(id, from.toMillis(), to.toMillis())
					.then(res => {
						generateInvoice(res.data)
						addJobToFirebase(res.data)
					})
					.catch(err => toast.error(err?.response?.data?.message))
			} else {
				eventService
					.updateEvent(id, {
						title: data.title,
						description: data.description,
						duration: data.duration,
						guests: data.guests,
						allDay: data.allDay,
						colorCode: data.color,
						id_company: data.companyId,
						from: from.toMillis(),
						to: to.toMillis()
					})
					.then(() => {
						onUpdate()
					})
					.catch(err => toast.error(err?.response?.data?.message))
			}
		},
		[time]
	)

	const [{ isOver, canDrop }, dropRef] = useDrop(() => ({
		accept: 'daily',
		drop: (item: any) => {
			updateTime(item)
		},
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		})
	}))

	return (
		<div ref={dropRef} className={isOver ? (canDrop ? '!bg-green-200' : '!bg-red-200') : ''}>
			{showLabel && (
				<div
					ref={time === '07:00' ? timeRef : null}
					className="!bg-white sticky left-0 -ml-14 -mt-2.5 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
					{time}
				</div>
			)}
		</div>
	)
}

interface HourlySlotProps {
	hour: string
	onUpdate: () => void
}

const HourlySlot = ({ hour, onUpdate }: HourlySlotProps) => {
	const updatedHour = mappedHours[hour]
	const halfHour =
		updatedHour.substring(0, updatedHour.length - 4) +
		'3' +
		updatedHour.substring(updatedHour.length - 3)
	return (
		<>
			<MiniSlot time={hour} onUpdate={onUpdate} showLabel />
			<MiniSlot time={halfHour} isHalfHour onUpdate={onUpdate} />
		</>
	)
}

export default HourlySlot
