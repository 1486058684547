import { useCallback } from 'react'
import { useDrop } from 'react-dnd'
import { toast } from 'react-toastify'

import clsx from 'clsx'
import { DateTime } from 'luxon'
import QRCode from 'qrcode'

import { pdf } from '@react-pdf/renderer'
import { CalendarEvent } from 'components/calendar/index'
import EventCard from 'components/calendar/month-calendar/event-card'
import { ApppointmentDocument } from 'pages/appointments/pdf'
import appointmentService from 'services/appointment-service'
import eventService from 'services/event-service'
import addJobToFirebase from '../../../firebase/utility'

interface DailySlotProps {
	events: CalendarEvent[]
	day: any
	index: number
	currentDate: any
	daysLength: number
	onDateClick: Function
	handleEventSelection: Function
	onUpdate: () => void
	isDragging: boolean
	setIsDragging: React.Dispatch<React.SetStateAction<boolean>>
}

const DailySlot = ({
	events,
	day,
	index,
	onDateClick,
	currentDate,
	daysLength,
	handleEventSelection,
	onUpdate,
	isDragging,
	setIsDragging
}: DailySlotProps) => {
	const updateDay = useCallback(
		(event: any) => {
			const { id, startDateTime, endDateTime, ...data } = event
			const from = DateTime.fromISO(startDateTime).set({ day: day.day })
			const to = DateTime.fromISO(endDateTime).set({ day: day.day })

			const generateInvoice = (appointment: Appointment) => {
				const sendPdf = async () => {
					const qrCodeURL = await QRCode.toDataURL(
						`https://pro.stylingcorner.ch/appointment-details/${appointment._id}`,
						{
							margin: 0,
							width: 80
						}
					)
					const blob = await pdf(
						<ApppointmentDocument qrCodeURL={qrCodeURL} appointment={appointment} />
					).toBlob()
					const formData = new FormData()
					const fileName = `${appointment.id_client.fname + '_' + DateTime.fromMillis(appointment.from).toFormat('dd.LL.yyyy')}_appointment.pdf`
					formData.append('file', blob, `${fileName}.pdf`)
					formData.append('id_client', appointment.id_client._id)
					formData.append('id_company', appointment.id_company._id)
					await appointmentService.sendEmail(appointment._id, formData)
					onUpdate()
				}
				sendPdf()
			}

			if (event.type === 'appointment') {
				appointmentService
					.updateAppointmentSlots(id, from.toMillis(), to.toMillis())
					.then(res => {
						generateInvoice(res.data)
						addJobToFirebase(res.data)
					})
					.catch(err => toast.error(err?.response?.data?.message))
			} else {
				eventService
					.updateEvent(id, {
						title: data.title,
						description: data.description,
						duration: data.duration,
						guests: data.guests,
						allDay: data.allDay,
						colorCode: data.color,
						id_company: data.companyId,
						from: from.toMillis(),
						to: to.toMillis()
					})
					.then(() => {
						onUpdate()
					})
					.catch(err => toast.error(err?.response?.data?.message))
			}
		},
		[day]
	)

	const [{ isOver, canDrop }, dropRef] = useDrop(() => ({
		accept: 'monthly',
		drop: (item: any) => {
			updateDay(item)
		},
		collect: monitor => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		})
	}))

	const dayEvents = events.filter(event =>
		DateTime.fromISO(event.startDateTime).hasSame(day, 'day')
	)

	const isWeekend = day.weekday === 6 || day.weekday === 7

	return (
		<div
			key={day.toISODate()}
			className={clsx(
				'relative px-3 py-2 h-[158px]',
				day.hasSame(currentDate, 'month')
					? isWeekend
						? 'bg-gray-50 text-gray-500'
						: 'bg-white'
					: 'bg-gray-50 text-gray-500',
				{
					'rounded-tl-xl': index === 0,
					'rounded-tr-xl': index === 6,
					'rounded-bl-xl': index === 28,
					'rounded-br-xl': index === daysLength - 1
				},
				isOver ? (canDrop ? '!bg-green-200' : '!bg-red-200') : ''
			)}
			ref={dropRef}>
			<time
				dateTime={day.toISODate() as string}
				onClick={() => onDateClick(day)}
				className={
					day.hasSame(DateTime.now(), 'day')
						? 'flex cursor-pointer h-6 w-6 items-center justify-center rounded-full bg-primary-light font-semibold text-white'
						: 'cursor-pointer'
				}>
				{day.day}
			</time>
			{dayEvents.length > 0 && (
				<ol className="mt-2 -ml-3 flex flex-col gap-1">
					{dayEvents.slice(0, 2).map(event => (
						<EventCard
							key={event.id}
							event={event}
							day={day}
							isDragging={isDragging}
							setIsDragging={setIsDragging}
							handleEventSelection={handleEventSelection}
						/>
					))}
					{dayEvents.length > 2 && (
						<li className=" bg-[#32323214] border-l-[3px] border-[#000000] px-1.5">
							<p className="text-primary text-xs">+ {dayEvents.length - 2} more</p>
						</li>
					)}
				</ol>
			)}
		</div>
	)
}

export default DailySlot
