import axios from 'services/axios'

const getCompanyUsers = (): Promise<{ blocked_clients: User[]; users: User[] }> => {
	return axios.get('auth/company-clients').then(response => response.data)
}

const getUserById = (id: string): Promise<User> => {
	return axios.get(`/auth/client/${id}`).then(response => response.data)
}

const getUserRelations = (id: string): Promise<RelatedPerson[]> => {
	return axios.get(`/relations/company/${id}`).then(response => response.data)
}

const createUser = (data: UserForm) => {
	return axios.post('/auth/pro/signup', data).then(response => {
		return response.data
	})
}

const updateUserProfile = (formData: FormData) => {
	return axios
		.post('/user/profile', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
		.then(response => response.data)
}

const updateUserPassword = (old_password: string, new_password: string) => {
	return axios
		.put('/auth/password/user', { old_password, new_password })
		.then(response => response.data)
}

const sendRegistrationEmail = (data: {
	name: string
	phone: string
	email: string
	password: string
}) => {
	return axios.post('/auth/client/sendUserData', data).then(res => res.data)
}

const userService = {
	getCompanyUsers,
	getUserRelations,
	getUserById,
	updateUserProfile,
	sendRegistrationEmail,
	updateUserPassword,
	createUser
}

export default userService
