import {
	ArrowPathIcon,
	ChevronLeftIcon,
	PencilIcon,
	PlusCircleIcon,
	TrashIcon,
	XMarkIcon
} from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactQuill from 'react-quill'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import clsx from 'clsx'
import { DateTime } from 'luxon'
import QRCode from 'qrcode'

import { Button } from 'components/app/button'
import { Modal } from 'components/app/modal'
import { useAppSelector } from 'hooks'
import appointmentService from 'services/appointment-service'
import eventService from 'services/event-service'
import { getTKey } from 'utils/language'

import { pdf } from '@react-pdf/renderer'
import { Select } from 'components/inputs/select'
import { ApppointmentDocument } from 'pages/appointments/pdf'
import addJobToFirebase from '../../firebase/utility'
import '../../pages/appointments/notes.css'

export const EventDetail = ({
	openNotePopup,
	openAppointmentPopup,
	setOpenAppointmentPopup,
	setOpenNotePopup,
	onAppointmentClick,
	onEventClick,
	refetch,
	...data
}: any) => {
	const { t } = useTranslation()
	const tKey = getTKey('calendar.eventDetails')

	const auth = useAppSelector(state => state.auth)

	const [noteValue, setNoteValue] = useState<string>(data?.notes ?? '')
	const [eventDetail, setEventDetail] = useState<CustomEvent>()
	const [appointmentDetails, setAppointmentDetails] = useState<Appointment>()
	const [selectedAppointment, setSelectedAppointment] = useState<Appointment>()
	const [selectedDuration, setSelectedDuration] = useState<string>()

	let extendDuration = [
		{ label: '15 min', value: '15' },
		{ label: '30 min', value: '30' },
		{ label: '45 min', value: '45' },
		{ label: '1 hour', value: '60' },
		{ label: '1 hour 15 min', value: '75' },
		{ label: '1 hour 30 min', value: '90' },
		{ label: '1 hour 45 min', value: '105' },
		{ label: '2 hours', value: '120' },
		{ label: '2 hours 15 min', value: '135' },
		{ label: '2 hours 30 min', value: '150' },
		{ label: '2 hours 45 min', value: '165' },
		{ label: '3 hours', value: '180' }
	]
	if (appointmentDetails) {
		const endDateTime = DateTime.fromMillis(appointmentDetails.to)
		const endOfDay = endDateTime.endOf('day')

		const remainingTimeInMinutes = endOfDay.diff(endDateTime, 'minutes').minutes

		extendDuration = extendDuration.filter(option => {
			return parseInt(option.value) <= remainingTimeInMinutes
		})
	}

	useEffect(() => {
		if (data.id) {
			if (data.type === 'event') {
				eventService.getEventById(data.id).then(res => setEventDetail(res))
			} else if (data.type === 'appointment') {
				appointmentService.getAppointmentById(data.id).then(res => setAppointmentDetails(res))
			}
		}
	}, [data.id])

	const onDelete = (id: string) => {
		if (id) {
			eventService
				.deleteEvent(id)
				.then(() => {
					toast.success(t('calendar.createEvent.toast.eventDelete'))
					refetch()
				})
				.catch(err =>
					toast.error(
						err?.response?.data?.message ?? t(tKey('calendar.createEvent.toast.eventError'))
					)
				)
		}
	}

	const createEvent = () => {
		if (eventDetail) {
			eventService
				.updateEvent(data.id, {
					title: eventDetail.title,
					notes: noteValue,
					description: eventDetail.description,
					duration: eventDetail.duration,
					guests: eventDetail.guests.map(
						(guest: { _id: string; fname: string; lname: string }) => guest._id
					),
					allDay: eventDetail.allDay,
					colorCode: eventDetail.colorCode,
					id_company: eventDetail.id_company,
					from: eventDetail.from,
					to: eventDetail.to
				})
				.then(() => {
					toast.success(t('calendar.createEvent.toast.eventUpdate'))
					refetch()
				})
				.catch(err =>
					toast.error(err?.response?.data?.message ?? t('calendar.createEvent.toast.eventError'))
				)
		}
	}

	const generateInvoice = (appointment: Appointment) => {
		const sendPdf = async () => {
			const qrCodeURL = await QRCode.toDataURL(
				`https://pro.stylingcorner.ch/appointment-details/${appointment._id}`,
				{
					margin: 0,
					width: 80
				}
			)
			const blob = await pdf(
				<ApppointmentDocument qrCodeURL={qrCodeURL} appointment={appointment} />
			).toBlob()
			const formData = new FormData()
			const fileName = `${appointment.id_client.fname + '_' + DateTime.fromMillis(appointment.from).toFormat('dd.LL.yyyy')}_appointment.pdf`
			formData.append('file', blob, `${fileName}.pdf`)
			formData.append('id_client', appointment.id_client._id)
			formData.append('id_company', appointment.id_company._id)
			await appointmentService.sendEmail(appointment._id, formData)
			refetch()
			toast.success(t('calendar.createEvent.toast.updateAppointmentSuccess'))
		}
		sendPdf()
	}

	const updateAppointment = () => {
		if (appointmentDetails) {
			const startTime = appointmentDetails.from
			const endTime = DateTime.fromMillis(appointmentDetails.to)
				.plus({ minutes: Number(selectedDuration) })
				.toMillis()

			appointmentService
				.updateAppointmentSlots(appointmentDetails?._id as string, startTime, endTime)
				.then(res => {
					generateInvoice(res.data)
					addJobToFirebase(res.data)
				})
				.catch(err =>
					toast.error(err?.response?.data?.message ?? t(tKey('toast.updateAppointmentError')))
				)
		}
	}

	useEffect(() => {
		if (auth.companyId && data.type === 'appointment') {
			appointmentService.getCompanyAppointments(auth.companyId).then((res: Appointment[]) => {
				const selectedAppointment = res.find(r => r._id === data.id)
				setSelectedAppointment(selectedAppointment as Appointment)
			})
		}
	}, [auth])

	return (
		<div
			style={{ boxShadow: '0px 4px 24px 0px #657D9338' }}
			className="bg-white md:translate-x-[-20%] md:translate-y-[-50%] md:fixed md:left-1/2 md:top-1/2 md:z-[100] md:w-[337px] border border-[#F5F7FA] py-4 rounded-lg flex flex-col gap-3.5">
			{openNotePopup && (
				<Modal isFullHeight width="w-[400px]" showCrossIcon={false} noPadding>
					<div className="sticky max-md:grid max-md:grid-flow-col max-md:auto-cols-auto md:flex items-center md:justify-between top-0 z-10 bg-white max-md:p-5 md:px-8 py-4 border-b border-border">
						<ChevronLeftIcon
							onClick={() => setOpenNotePopup(false)}
							className="w-3 h-3 md:hidden cursor-pointer"
						/>
						<h1 className="text-primary text-[20px] max-md:text-center whitespace-nowrap max-md:text-sm font-domine font-bold">
							{data?.notes ? t(tKey('labels.updateNote')) : t(tKey('labels.addNote'))}
						</h1>
						<div className="md:hidden" />
						<XMarkIcon
							onClick={() => setOpenNotePopup(false)}
							className="text-primary max-md:hidden bg-white h-6 w-6 cursor-pointer"
						/>
					</div>
					<div className="p-4">
						<ReactQuill
							className="treatment editor"
							theme="snow"
							placeholder={t(tKey('labels.enterNoteHere'))}
							value={noteValue}
							onChange={setNoteValue}
						/>
						<Button
							onClick={createEvent}
							disabled={!noteValue || noteValue === '<p><br></p>' || noteValue === data?.notes}
							className={clsx(
								'text-sm mt-3 w-full px-8 py-2',
								!noteValue || noteValue === '<p><br></p>' || noteValue === data?.notes
									? 'opacity-30'
									: 'opacity-100'
							)}>
							{data?.notes ? t(tKey('labels.updateNote')) : t(tKey('labels.addNote'))}
						</Button>
					</div>
				</Modal>
			)}
			{openAppointmentPopup && (
				<Modal isFullHeight width="w-[500px]" showCrossIcon={false} noPadding>
					<div className="sticky max-md:grid max-md:grid-flow-col max-md:auto-cols-auto md:flex items-center md:justify-between top-0 z-10 bg-white max-md:p-5 md:px-8 py-4 border-b border-border">
						<ChevronLeftIcon
							onClick={() => setOpenAppointmentPopup(false)}
							className="w-3 h-3 md:hidden cursor-pointer"
						/>
						<h1 className="text-primary text-[20px] max-md:text-center whitespace-nowrap max-md:text-sm font-domine font-bold">
							{t(tKey('labels.extendTime'))}
						</h1>
						<div className="md:hidden" />
						<XMarkIcon
							onClick={() => setOpenAppointmentPopup(false)}
							className="text-primary max-md:hidden bg-white h-6 w-6 cursor-pointer"
						/>
					</div>
					<div className="p-4">
						<Select
							labelText={t('services.create.labels.duration')}
							name="duration"
							onChange={e => setSelectedDuration(e.target.value)}
							value={selectedDuration}>
							<option value="">{t('services.create.labels.selectDuration')}</option>
							{extendDuration.map(duration => (
								<option key={duration.label} value={duration.value}>
									{duration.label}
								</option>
							))}
						</Select>
						<Button
							onClick={updateAppointment}
							disabled={!selectedDuration}
							className={clsx(
								'text-sm mt-3 w-full px-8 py-2',
								!selectedDuration ? 'opacity-30' : 'opacity-100'
							)}>
							{t(tKey('labels.extendTime'))}
						</Button>
					</div>
				</Modal>
			)}
			<div className="px-6">
				{data.type === 'appointment' ? (
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						className="shrink-0"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M4.76925 20.5771C4.34742 20.5771 3.98875 20.4294 3.69325 20.1339C3.39775 19.8382 3.25 19.4796 3.25 19.0579V8.59615C3.25 8.17448 3.39775 7.8159 3.69325 7.5204C3.98875 7.2249 4.34742 7.07715 4.76925 7.07715H7.25V4.59615C7.25 4.17448 7.39775 3.8159 7.69325 3.5204C7.98875 3.2249 8.34742 3.07715 8.76925 3.07715H15.2308C15.6526 3.07715 16.0113 3.2249 16.3068 3.5204C16.6023 3.8159 16.75 4.17448 16.75 4.59615V11.0771H19.2308C19.6526 11.0771 20.0113 11.2249 20.3068 11.5204C20.6023 11.8159 20.75 12.1745 20.75 12.5961V19.0579C20.75 19.4796 20.6023 19.8382 20.3068 20.1339C20.0113 20.4294 19.6526 20.5771 19.2308 20.5771H13.25V16.5771H10.75V20.5771H4.76925ZM4.75 19.0771H7.25V16.5771H4.75V19.0771ZM4.75 15.0771H7.25V12.5771H4.75V15.0771ZM4.75 11.0771H7.25V8.57715H4.75V11.0771ZM8.75 15.0771H11.25V12.5771H8.75V15.0771ZM8.75 11.0771H11.25V8.57715H8.75V11.0771ZM8.75 7.07715H11.25V4.57715H8.75V7.07715ZM12.75 15.0771H15.25V12.5771H12.75V15.0771ZM12.75 11.0771H15.25V8.57715H12.75V11.0771ZM12.75 7.07715H15.25V4.57715H12.75V7.07715ZM16.75 19.0771H19.25V16.5771H16.75V19.0771ZM16.75 15.0771H19.25V12.5771H16.75V15.0771Z"
							fill="#7F9AB2"
						/>
					</svg>
				) : (
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M18.6921 12.7495H16.5769C16.364 12.7495 16.1858 12.6777 16.0421 12.534C15.8986 12.3905 15.8269 12.2124 15.8269 11.9995C15.8269 11.7867 15.8986 11.6085 16.0421 11.465C16.1858 11.3214 16.364 11.2495 16.5769 11.2495H18.6921C18.905 11.2495 19.0832 11.3214 19.2269 11.465C19.3704 11.6085 19.4421 11.7867 19.4421 11.9995C19.4421 12.2124 19.3704 12.3905 19.2269 12.534C19.0832 12.6777 18.905 12.7495 18.6921 12.7495ZM14.5326 16.713C14.6608 16.5374 14.8245 16.436 15.0239 16.409C15.2232 16.3822 15.4107 16.4329 15.5864 16.561L17.2729 17.8283C17.4485 17.9566 17.5499 18.1205 17.5769 18.3198C17.6037 18.5191 17.553 18.7066 17.4249 18.8823C17.2967 19.0578 17.1329 19.159 16.9336 19.186C16.7341 19.213 16.5466 19.1624 16.3711 19.034L14.6844 17.7668C14.5089 17.6386 14.4076 17.4749 14.3806 17.2755C14.3538 17.0762 14.4045 16.8887 14.5326 16.713ZM17.2344 6.1323L15.5479 7.39955C15.3722 7.52771 15.1847 7.57838 14.9854 7.55155C14.786 7.52455 14.6223 7.42321 14.4941 7.24755C14.366 7.07205 14.3153 6.88455 14.3421 6.68505C14.3691 6.48571 14.4705 6.32196 14.6461 6.1938L16.3326 4.92655C16.5083 4.79838 16.6958 4.74771 16.8951 4.77455C17.0945 4.80155 17.2582 4.9028 17.3864 5.0783C17.5145 5.25396 17.5652 5.44146 17.5384 5.6408C17.5114 5.8403 17.41 6.00413 17.2344 6.1323ZM7.26912 14.4995H4.46137C4.2037 14.4995 3.9887 14.4133 3.81637 14.2408C3.64387 14.0685 3.55762 13.8534 3.55762 13.5955V10.4035C3.55762 10.1457 3.64387 9.93063 3.81637 9.7583C3.9887 9.5858 4.2037 9.49955 4.46137 9.49955H7.26912L10.2614 6.5073C10.5012 6.26763 10.7772 6.2128 11.0894 6.3428C11.4015 6.47296 11.5576 6.70855 11.5576 7.04955V16.9495C11.5576 17.2905 11.4015 17.5261 11.0894 17.6563C10.7772 17.7863 10.5012 17.7315 10.2614 17.4918L7.26912 14.4995Z"
							fill="#7F9AB2"
						/>
					</svg>
				)}
			</div>
			<div
				style={{ borderColor: data.color }}
				className="border-l-[3px] px-5 flex flex-col gap-1.5">
				<div className="flex justify-between items-center">
					<div className="flex flex-col">
						<h1 className="text-primary font-bold">{data.name}</h1>
						{data.type === 'appointment' && (
							<Link
								className="text-xs whitespace-nowrap text-primary-light hover:underline"
								to={`/appointment-details/${data.id}`}>
								{t(tKey('labels.viewAppointmentDetails'))}
							</Link>
						)}
					</div>
					{data.type === 'event' && data.addedBy._id === auth._id && (
						<div className="flex gap-1.5 items-center">
							<PencilIcon
								onClick={() => onEventClick(data.id)}
								className="h-4 w-4 text-[#7F9AB2] hover:text-primary-light cursor-pointer"
							/>
							<div className="border-l shrink-0 border-[#EEEEEE] h-5 w-0.5" />
							<TrashIcon
								onClick={event => {
									event.stopPropagation()
									onDelete(data.id)
								}}
								className="h-4 w-4 text-[#7F9AB2] hover:text-red-500 cursor-pointer"
							/>
						</div>
					)}
					{data.type === 'appointment' && (
						<div className="flex gap-1.5 items-center">
							<PencilIcon
								onClick={() => onAppointmentClick(data.id)}
								className="h-4 w-4 text-[#7F9AB2] hover:text-primary-light cursor-pointer"
							/>
						</div>
					)}
				</div>
				{data.description && <p className="text-primary text-sm leading-5">{data.description}</p>}
			</div>
			<div className="flex flex-col items-start gap-2 px-5">
				<p className="text-sm">
					{DateTime.fromISO(data.startDateTime).toFormat('dd LLLL, yyyy')} -{' '}
					{DateTime.fromISO(data.endDateTime).toFormat('dd LLLL, yyyy')}
				</p>
				<div className="flex items-center w-full justify-between">
					<div className="rounded-full py-0.5 px-2.5 bg-[#F5F7FA] ">
						<time className="text-[#4C6780] font-semibold text-sm text-center">
							{data.allDay
								? t(tKey('labels.allDay'))
								: `${DateTime.fromISO(data.startDateTime).toFormat('HH:mm')} - ${DateTime.fromISO(
										data.endDateTime
									).toFormat('HH:mm')}`}
						</time>
					</div>
					{data.type === 'event' && data.addedBy._id === auth._id && (
						<div
							onClick={() => setOpenNotePopup(true)}
							className="flex hover:underline cursor-pointer text-primary-light items-center gap-x-0.5">
							<PlusCircleIcon className="size-4" />
							<p className="text-sm">{t(tKey('labels.addNote'))}</p>
						</div>
					)}
					{data.type === 'appointment' &&
						appointmentDetails &&
						DateTime.now() < DateTime.fromMillis(appointmentDetails?.from) && (
							<div
								onClick={() => setOpenAppointmentPopup(true)}
								className="flex hover:underline cursor-pointer text-primary-light items-center gap-x-0.5">
								<ArrowPathIcon className="size-4" />
								<p className="text-sm">{t(tKey('labels.extendTime'))}</p>
							</div>
						)}
				</div>
			</div>
			{data.type === 'event' && data.guests.length > 0 && (
				<div className="flex flex-col gap-2">
					<h5 className="font-domine font-bold text-xs text-primary px-5">
						{t(tKey('labels.participants'))}
						<ul className="flex flex-col px-4 pt-2 gap-2">
							{data.guests.map((guest: { _id: string; fname: string; lname: string }) => (
								<li
									className="text-[#4C6780] text-xs list-disc [&::marker]:text-primary-light"
									key={guest._id}>
									{guest.fname + ' ' + guest.lname}
								</li>
							))}
						</ul>
					</h5>
				</div>
			)}
			{data.type === 'appointment' && (
				<div className="flex flex-col gap-2 px-5">
					<h2 className="font-domine font-bold text-primary text-xs">
						{t(tKey('labels.services'))}
					</h2>
					<div className="bg-[#D3E3F180] w-fit py-0.5 px-3 rounded-full">
						<p className="font-semibold text-[#4C6780] text-xs">
							{selectedAppointment?.service_name}
						</p>
					</div>
				</div>
			)}
			<div className="px-5 flex gap-x-1.5 items-center">
				<svg
					width="28"
					height="24"
					viewBox="0 0 28 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<circle cx="13.6001" cy="12" r="10" fill="#61BC5F" />
					<path
						d="M13.5999 11.6313C13.1014 11.6313 12.6768 11.4559 12.3261 11.1051C11.9753 10.7543 11.7999 10.3297 11.7999 9.83127C11.7999 9.3328 11.9753 8.90818 12.3261 8.55741C12.6768 8.20664 13.1014 8.03125 13.5999 8.03125C14.0984 8.03125 14.523 8.20664 14.8737 8.55741C15.2245 8.90818 15.3999 9.3328 15.3999 9.83127C15.3999 10.3297 15.2245 10.7543 14.8737 11.1051C14.523 11.4559 14.0984 11.6313 13.5999 11.6313ZM9.3999 15.3466V14.982C9.3999 14.7343 9.47202 14.5026 9.61625 14.2868C9.76048 14.0711 9.95452 13.9036 10.1984 13.7843C10.7645 13.5128 11.3311 13.3091 11.898 13.1734C12.4649 13.0376 13.0322 12.9697 13.5999 12.9697C14.1676 12.9697 14.7349 13.0376 15.3018 13.1734C15.8688 13.3091 16.4353 13.5128 17.0014 13.7843C17.2453 13.9036 17.4393 14.0711 17.5836 14.2868C17.7278 14.5026 17.7999 14.7343 17.7999 14.982V15.3466C17.7999 15.5228 17.7401 15.6707 17.6205 15.7903C17.5009 15.9099 17.353 15.9697 17.1768 15.9697H10.023C9.84682 15.9697 9.69894 15.9099 9.57932 15.7903C9.45971 15.6707 9.3999 15.5228 9.3999 15.3466Z"
						fill="white"
					/>
				</svg>
				<p className="text-primary font-semibold text-semibold text-sm">
					{data.type === 'appointment'
						? data.id_client?.fname + ' ' + data.id_client?.lname
						: data.type === 'event'
							? data.addedBy?.fname + ' ' + data.addedBy?.lname
							: data.assignedBy?.fname + ' ' + data.assignedBy?.lname}
				</p>
			</div>
		</div>
	)
}
