import { Combobox, Disclosure, Transition } from '@headlessui/react'
import { XCircleIcon } from '@heroicons/react/20/solid'
import {
	ArrowDownTrayIcon,
	ChevronDownIcon,
	ChevronLeftIcon,
	ChevronRightIcon,
	ChevronUpIcon,
	PencilIcon,
	UserPlusIcon,
	XMarkIcon
} from '@heroicons/react/24/outline'
import { pdf } from '@react-pdf/renderer'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import clsx from 'clsx'
import { DateTime } from 'luxon'
import QRCode from 'qrcode'

import { ProfilePhotoIcon } from 'assets/icons'
import { Spinner } from 'components/animations/spinner'
import { Button } from 'components/app/button'
import { AppLayout } from 'components/app/layout'
import { EllipsisMenu, MenuDropdown } from 'components/app/menu'
import { Modal } from 'components/app/modal'
import { Pagination } from 'components/app/pagination'
import { Table } from 'components/app/table'
import { InputDate } from 'components/inputs/date'
import { AppointmentStatus, mappedAppointmentStatuses } from 'constants/constants'
import { useAppSelector } from 'hooks'
import useIsMobile from 'hooks/useIsMobile'
import { CreateAppointment } from 'pages/appointments/create'
import { ApppointmentDocument } from 'pages/appointments/pdf'
import { CreateClient } from 'pages/clients/create'
import appointmentService from 'services/appointment-service'
import categoryService from 'services/category-service'
import employeeService from 'services/employee-service'
import { getAppLang, getTKey } from 'utils/language'

export const Appointments = () => {
	const { t } = useTranslation()
	const isMobile = useIsMobile()
	const navigate = useNavigate()
	const [searchParams, _setSearchParams] = useSearchParams()

	const tKey = getTKey('appointments')
	const appLanguage = getAppLang()

	const auth = useAppSelector(state => state.auth)

	const [searchText, setSearchText] = useState('')
	const [showModal, setShowModal] = useState(false)
	const [showClientModal, setShowClientModal] = useState(false)
	const [createdClient, setCreatedClient] = useState<string>()
	const [showEmployeeModal, setShowEmployeeModal] = useState<{ visibility: boolean; id: string }>()
	const [forceRefresh, setForceRefresh] = useState(false)
	const [companyAppointments, setCompanyAppointments] = useState<Appointment[]>([])
	const [selectedDate, setSelectedDate] = useState<string>()
	const [isLoading, setIsLoading] = useState(false)
	const [availableEmployees, setAvailableEmployees] = useState<Employee[]>()
	const [isSearchVisible, setIsSearchVisible] = useState(false)
	const [assigningEmployee, setAssigningEmployee] = useState(false)
	const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false)
	const [employeeQuery, setEmployeeQuery] = useState('')
	const [selectedEmployee, setSelectedEmployee] = useState<string>()
	const [companyCategories, setCompanyCategories] = useState<{ [id: string]: Category }>()
	const [selectedCategory, setSelectedCategory] = useState<string>(
		searchParams.get('category') ?? 'allCategories'
	)
	const [selectedStatus, setSelectedStatus] = useState<string>(
		searchParams.get('status') || 'allStatus'
	)
	const [sorting, setSorting] = useState<{ isSorted: boolean; type: 'asc' | 'desc' }>()

	useEffect(() => {
		if (showEmployeeModal?.id) {
			employeeService
				.getCompanyEmployees()
				.then(res => setAvailableEmployees(res))
				.finally(() => setIsLoading(false))
		}
	}, [showEmployeeModal])

	useEffect(() => {
		if (auth.is_adminUser === true) {
			categoryService.getCompanyCategories().then(res => {
				const mappedCategories = res
					.filter(r => r.is_active === 'aktiv')
					.reduce((agg, curr) => ({ ...agg, [curr._id]: curr }), {} as { [id: string]: Category })
				setCompanyCategories(mappedCategories)
			})
		} else {
			categoryService.getCompanyCategoriesForEmployee().then(res => {
				const mappedCategories = res
					.filter(r => r.is_active === 'aktiv')
					.reduce((agg, curr) => ({ ...agg, [curr._id]: curr }), {} as { [id: string]: Category })
				setCompanyCategories(mappedCategories)
			})
		}
		if (auth.companyId) {
			if (auth.is_adminUser === true) {
				appointmentService
					.getCompanyAppointments(auth.companyId)
					.then(res => setCompanyAppointments(res))
			} else {
				appointmentService
					.getCompanyAppointments(auth.companyId)
					.then(res => setCompanyAppointments(res))
			}
		}
	}, [auth, forceRefresh])

	const { filteredAppointments } = useMemo(() => {
		const { filteredAppointments } = companyAppointments
			.filter(appointment =>
				selectedCategory === 'allCategories'
					? true
					: selectedCategory === companyCategories?.[appointment.id_category].category_name
			)
			.filter(appointment =>
				selectedStatus === 'allStatus'
					? true
					: mappedAppointmentStatuses[appointment.status as AppointmentStatus] === selectedStatus
			)
			.filter(appointment =>
				searchParams.get('type') === 'upcoming'
					? DateTime.fromMillis(appointment.from) > DateTime.now()
					: true
			)
			.reduce(
				(prev, curr) => {
					if (searchParams.has('category')) {
						searchParams.delete('category')
						const newUrl = `${window.location.pathname}${window.location.hash}`
						window.history.replaceState({}, '', newUrl)
					}
					if (searchParams.has('status')) {
						searchParams.delete('status')
						const newUrl = `${window.location.pathname}${window.location.hash}`
						window.history.replaceState({}, '', newUrl)
					}
					if (searchText) {
						const fullName = curr.id_client?.fname + ' ' + curr.id_client?.lname
						if (
							curr.appointmentNumber?.toString().padStart(12, '0').includes(searchText) ||
							fullName?.toLowerCase().includes(searchText.toLowerCase()) ||
							companyCategories?.[curr.id_category]?.category_name
								?.toLowerCase()
								.includes(searchText.toLowerCase()) ||
							curr.service_name?.toLowerCase().includes(searchText.toLowerCase()) ||
							mappedAppointmentStatuses[curr.status as AppointmentStatus]
								?.toLowerCase()
								.includes(searchText.toLowerCase()) ||
							DateTime.fromMillis(Number(curr.id_client?.birthDate))
								.toFormat('dd.LL.yyyy')
								.includes(searchText) ||
							DateTime.fromMillis(curr.from).toFormat('dd.LL.yyyy').includes(searchText)
						) {
							return { filteredAppointments: [...prev.filteredAppointments, curr] }
						}
					} else if (selectedDate) {
						if (
							DateTime.fromMillis(curr.from).hasSame(
								DateTime.fromFormat(selectedDate, 'dd LLL yyyy'),
								'day'
							)
						) {
							return { filteredAppointments: [...prev.filteredAppointments, curr] }
						}
					} else {
						return { filteredAppointments: [...prev.filteredAppointments, curr] }
					}
					return prev
				},
				{
					filteredAppointments: [] as Appointment[]
				}
			)
		return { filteredAppointments }
	}, [companyAppointments, searchText, selectedCategory, selectedStatus, selectedDate])

	const generateInvoice = (appointment: Appointment) => {
		const generatePdf = async () => {
			const qrCodeURL = await QRCode.toDataURL(
				`https://pro.stylingcorner.ch/appointment-details/${appointment._id}`,
				{
					margin: 0,
					width: 80
				}
			)
			const blob = await pdf(
				<ApppointmentDocument qrCodeURL={qrCodeURL} appointment={appointment} />
			).toBlob()
			const url = URL.createObjectURL(blob)

			const fileName = `${appointment.id_client.fname + '_' + DateTime.fromMillis(appointment.from).toFormat('dd.LL.yyyy')}_appointment.pdf`
			const link = document.createElement('a')
			link.href = url
			link.download = `${fileName}.pdf`
			link.target = '_blank'

			document.body.appendChild(link)
			link.click()

			document.body.removeChild(link)
		}
		generatePdf()
	}

	const filteredEmployeeOptions = useMemo(() => {
		if (availableEmployees) {
			return availableEmployees.filter(user => {
				const name = user.fname + ' ' + user.lname
				return (
					employeeQuery === '' ||
					name
						.toLowerCase()
						.replace(/\s+/g, '')
						.includes(employeeQuery.toLowerCase().replace(/\s+/g, '')) ||
					user.city
						?.toLowerCase()
						.replace(/\s+/g, '')
						.includes(employeeQuery.toLowerCase().replace(/\s+/g, '')) ||
					user.zip
						?.toString()
						?.toLowerCase()
						.replace(/\s+/g, '')
						.includes(employeeQuery.toLowerCase().replace(/\s+/g, ''))
				)
			})
		} else {
			return availableEmployees
		}
	}, [availableEmployees, employeeQuery])

	const columns = [
		{
			header: 'Id',
			accessorKey: 'appointmentNumber',
			cell: (data: Cell) => (
				<div className="flex gap-x-3 items-center">
					<img
						src={
							data.row.original.id_client.profile_url === 'crm'
								? ProfilePhotoIcon
								: data.row.original.id_client.profile_url
						}
						className="h-6 w-6 object-cover rounded-full"
					/>
					{data.getValue().toString().padStart(12, '0')}
				</div>
			)
		},
		{
			header: t(tKey('tables.fullName')),
			accessorFn: (row: Appointment) => `${row.id_client.fname} ${row.id_client.lname}`
		},
		{
			header: t(tKey('tables.birthday')),
			accessorKey: 'id_client.birthDate',
			cell: (date: Cell) =>
				DateTime.fromMillis(Number(date.getValue())).isValid
					? DateTime.fromMillis(Number(date.getValue()))
							.setLocale(appLanguage)
							.toFormat('dd.LL.yyyy')
					: '-'
		},
		{
			header: t(tKey('tables.bookingFor')),
			accessorFn: (row: Appointment) => (row.relatedPerson ? row.relatedPerson.relation : 'Myself')
		},
		{
			header: t(tKey('tables.services')),
			accessorKey: 'service_name'
		},
		{
			header: t(tKey('tables.date')),
			accessorKey: 'from',
			cell: (date: Cell) => (
				<div className="flex flex-col">
					<span>
						{DateTime.fromMillis(date.getValue()).setLocale(appLanguage).toFormat('dd.LL.yyyy')}
					</span>
					<span>
						{DateTime.fromMillis(date.getValue()).setLocale(appLanguage).toFormat('HH:mm')}
					</span>
				</div>
			)
		},
		{
			header: t(tKey('tables.status')),
			accessorKey: 'status',
			cell: (status: Cell) => (
				<span
					className={clsx(
						'px-5 py-1 rounded-[20px] w-fit flex justify-center text-center whitespace-nowrap text-white font-semibold text-sm',
						{
							'bg-[#3577F1]': status.getValue() === AppointmentStatus.CONFIRMED,
							'bg-[#26B6A6]': status.getValue() === AppointmentStatus.COMPLETED,
							'bg-[#F84C6B]': status.getValue() === AppointmentStatus.CANCELLED,
							'bg-[#6291C2]': status.getValue() === AppointmentStatus.CHECKIN,
							'bg-[#F5B715]': status.getValue() === AppointmentStatus.PENDING,
							'bg-orange-500': status.getValue() === AppointmentStatus.PENDENT
						}
					)}>
					{mappedAppointmentStatuses[status.getValue() as AppointmentStatus]}
				</span>
			)
		},
		{
			id: 'action-appointment',
			hasNoHeading: true,
			cell: (data: Cell) => (
				<div className="flex gap-x-6 justify-end items-center">
					<svg
						onClick={() => navigate(`/appointment-details/${data.row.original._id}`)}
						xmlns="http://www.w3.org/2000/svg"
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						className="cursor-pointer group shrink-0">
						<path
							d="M5 19H6.09808L16.7962 8.30193L15.6981 7.20385L5 17.9019V19ZM4.80768 20C4.57884 20 4.38702 19.9226 4.2322 19.7678C4.0774 19.613 4 19.4212 4 19.1923V18.1519C4 17.9308 4.04359 17.7201 4.13078 17.5198C4.21794 17.3194 4.33397 17.1468 4.47885 17.0019L17.1808 4.28652C17.2832 4.19517 17.3963 4.12458 17.5201 4.07475C17.6439 4.02492 17.7729 4 17.9072 4C18.0416 4 18.1717 4.02115 18.2977 4.06345C18.4236 4.10577 18.5398 4.18205 18.6462 4.2923L19.7135 5.36537C19.8237 5.47179 19.899 5.58852 19.9394 5.71557C19.9798 5.84262 20 5.96968 20 6.09673C20 6.23224 19.9772 6.36189 19.9315 6.48568C19.8858 6.60946 19.8131 6.72257 19.7135 6.825L6.99808 19.5212C6.85321 19.666 6.6806 19.7821 6.48025 19.8692C6.27988 19.9564 6.06916 20 5.84808 20H4.80768ZM16.2375 7.76252L15.6981 7.20385L16.7962 8.30193L16.2375 7.76252Z"
							fill="#123258"
							className="group-hover:fill-[#123258] fill-[#7F9AB2]"
						/>
					</svg>
					<ArrowDownTrayIcon
						onClick={event => {
							event.stopPropagation()
							generateInvoice(data.row.original)
						}}
						className="cursor-pointer size-5 hover:text-[#123258] text-[#7F9AB2]"
					/>
					{!(data.row.original as Appointment).id_employee &&
						(auth.roles.includes('ROLE_ADMIN') || auth.roles.includes('ROLE_SUPERADMIN')) && (
							<svg
								onClick={event => {
									event.stopPropagation()
									setIsLoading(true)
									setShowEmployeeModal({ visibility: true, id: data.row.original._id })
								}}
								xmlns="http://www.w3.org/2000/svg"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth="1"
								stroke="currentColor"
								className="hover:stroke-[#123258] size-6 stroke-[#7F9AB2]">
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M18 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0ZM3 19.235v-.11a6.375 6.375 0 0 1 12.75 0v.109A12.318 12.318 0 0 1 9.374 21c-2.331 0-4.512-.645-6.374-1.766Z"
								/>
							</svg>
						)}
				</div>
			)
		}
	]

	if (isMobileFilterOpen) {
		return (
			<div>
				<div className="py-4 px-5 items-center grid grid-cols-3">
					<ChevronLeftIcon
						className="w-4 h-4 cursor-pointer"
						onClick={() => setIsMobileFilterOpen(false)}
					/>
					<h1 className="text-center font-domine font-bold text-sm text-primary">
						{t(tKey('titles.filters'))}
					</h1>
				</div>
				<div className="p-5 flex flex-col gap-y-5">
					<InputDate
						placeholder={t(tKey('placeholders.allTime'))}
						value={selectedDate}
						onDateChange={(value: string) => setSelectedDate(value)}
						className="w-full p-4 rounded"
					/>
					<select
						name="category"
						onChange={e => setSelectedCategory(e.target.value)}
						value={selectedCategory}
						className="text-primary text-sm font-semibold rounded p-4 border border-[#D3E3F1] focus:border-[#06AB91] focus:ring-0">
						<option value={'allCategories'}>{t('menu.labels.allCategories')}</option>
						{Object.values(companyCategories ?? {}).map(category => (
							<option key={category._id} value={category.category_name}>
								{category.category_name}
							</option>
						))}
					</select>
					<select
						name="status"
						onChange={e => setSelectedStatus(e.target.value)}
						value={selectedStatus}
						className="text-primary text-sm font-semibold rounded p-4 border border-[#D3E3F1] focus:border-[#06AB91] focus:ring-0">
						<option value={'allStatus'}>{t('menu.labels.allStatus')}</option>
						{Object.values(mappedAppointmentStatuses).map(status => (
							<option key={status} value={status}>
								{status}
							</option>
						))}
					</select>
					<div className="self-end flex gap-x-6">
						<button
							onClick={() => {
								setSelectedDate('')
								setSelectedCategory('allCategories')
								setSelectedStatus('allStatus')
							}}
							className="text-primary font-bold text-sm">
							{t(tKey('buttons.reset'))}
						</button>
						<Button onClick={() => setIsMobileFilterOpen(false)} className="font-bold text-sm">
							{t(tKey('buttons.apply'))}
						</Button>
					</div>
				</div>
			</div>
		)
	}

	const assignEmployee = () => {
		setAssigningEmployee(true)
		appointmentService
			.updateAppointment(showEmployeeModal?.id as string, { id_employee: selectedEmployee })
			.then(() => {
				toast.success(t(tKey('toasts.assignSuccess')))
				setShowEmployeeModal(undefined)
				setSelectedEmployee(undefined)
				setForceRefresh(prev => !prev)
			})
			.catch(err => toast.error(err?.response?.data?.message ?? t(tKey('toasts.assignError'))))
			.finally(() => setAssigningEmployee(false))
	}

	const renderMobileAppointmentsTable = (appointments: Appointment[]) => {
		return (
			<>
				{appointments
					.sort((a, b) => {
						if (sorting?.type === 'asc') {
							return a.id_client.fname.localeCompare(b.id_client.fname)
						} else if (sorting?.type === 'desc') {
							return b.id_client.fname.localeCompare(a.id_client.fname)
						}
						return 0
					})
					.map((appointment, index) => (
						<div key={appointment._id} className="w-full bg-white">
							<Disclosure>
								{({ open }) => (
									<>
										<Disclosure.Button
											className={clsx(
												'flex justify-between w-full px-3 py-2 border border-[#1C1C1C1A]',
												{
													'bg-background': open,
													'border-t-0': index > 0
												}
											)}>
											<div className="flex w-full items-center justify-between">
												<div className="flex gap-x-2.5 items-center">
													<ChevronRightIcon
														className={clsx('h-4 w-4 text-primary shrink-0', {
															'rotate-90 transform': open
														})}
													/>
													<img
														src={
															appointment.id_client.profile_url === 'crm'
																? ProfilePhotoIcon
																: appointment.id_client.profile_url
														}
														className="h-8 w-8 rounded-full object-cover"
													/>
													<div className="flex items-start flex-col gap-y-1">
														<h3 className="text-primary text-sm font-semibold">
															{appointment.id_client.fname + ' ' + appointment.id_client.lname}
														</h3>
														<div className="flex gap-x-2 items-center">
															<p className="text-tertiary text-xs">
																{appointment.appointmentNumber.toString().padStart(12, '0')}
															</p>
															<svg
																xmlns="http://www.w3.org/2000/svg"
																width="4"
																height="4"
																viewBox="0 0 4 4"
																fill="none"
																className="shrink-0">
																<circle cx="2" cy="2" r="2" fill="#D3E3F1" />
															</svg>
															<p
																className={clsx('text-xs whitespace-nowrap', {
																	'text-[#3577F1]':
																		appointment.status === AppointmentStatus.CONFIRMED,
																	'text-[#26B6A6]':
																		appointment.status === AppointmentStatus.COMPLETED,
																	'text-[#F84C6B]':
																		appointment.status === AppointmentStatus.CANCELLED,
																	'text-[#6291C2]':
																		appointment.status === AppointmentStatus.CHECKIN,
																	'text-[#F5B715]':
																		appointment.status === AppointmentStatus.PENDING,
																	'text-orange-500':
																		appointment.status === AppointmentStatus.PENDENT
																})}>
																{mappedAppointmentStatuses[appointment.status as AppointmentStatus]}
															</p>
														</div>
													</div>
												</div>
												<div className="-mt-3">
													<EllipsisMenu
														options={[
															...(!appointment.id_employee &&
															(auth.roles.includes('ROLE_ADMIN') ||
																auth.roles.includes('ROLE_SUPERADMIN'))
																? [
																		{
																			icon: UserPlusIcon,
																			color: 'text-primary',
																			action: () => {
																				setIsLoading(true)
																				setShowEmployeeModal({
																					visibility: true,
																					id: appointment._id
																				})
																			}
																		}
																	]
																: []),
															{
																icon: PencilIcon,
																color: 'text-primary',
																action: () => navigate(`/appointment-details/${appointment._id}`)
															},
															{
																icon: ArrowDownTrayIcon,
																color: 'text-primary',
																action: () => generateInvoice(appointment)
															}
														]}
													/>
												</div>
											</div>
										</Disclosure.Button>
										<Disclosure.Panel>
											<div className="flex px-6 py-2 gap-x-1.5 border-t-0 border border-[#1C1C1C1A] bg-background">
												<h5 className="text-primary font-semibold text-sm">
													{t(tKey('tables.birthday'))}:
												</h5>
												<p className="text-primary text-sm">
													{DateTime.fromMillis(Number(appointment.id_client.birthDate)).isValid
														? DateTime.fromMillis(Number(appointment.id_client.birthDate))
																.setLocale(appLanguage)
																.toFormat('dd.LL.yyyy')
														: '-'}
												</p>
											</div>
											<div className="flex px-6 py-2 gap-x-1.5 border-t-0 border border-[#1C1C1C1A] bg-background">
												<h5 className="text-primary font-semibold text-sm">
													{t(tKey('tables.date'))}:
												</h5>
												<p className="text-primary text-sm">
													{DateTime.fromMillis(appointment.from)
														.setLocale(appLanguage)
														.toFormat('dd.LL.yyyy')}
												</p>
											</div>
											<div className="flex px-6 py-2 gap-x-1.5 border-t-0 border border-[#1C1C1C1A] bg-background">
												<h5 className="text-primary font-semibold text-sm">
													{t(tKey('tables.time'))}:
												</h5>
												<p className="text-primary text-sm">
													{DateTime.fromMillis(appointment.from).toFormat('HH:mm')}
												</p>
											</div>
											<div className="flex px-6 py-2 gap-x-1.5 border-t-0 border border-[#1C1C1C1A] bg-background">
												<h5 className="text-primary font-semibold text-sm">
													{t(tKey('tables.bookingFor'))}:
												</h5>
												<p className="text-primary text-sm">
													{appointment.relatedPerson
														? appointment.relatedPerson.relation
														: 'Myself'}
												</p>
											</div>
											<div className="flex px-6 py-2 gap-x-1.5 border-t-0 border border-[#1C1C1C1A] bg-background">
												<h5 className="text-primary font-semibold text-sm">
													{t(tKey('tables.services'))}:
												</h5>
												<p className="text-primary text-sm">{appointment.service_name}</p>
											</div>
										</Disclosure.Panel>
									</>
								)}
							</Disclosure>
						</div>
					))}
			</>
		)
	}

	return (
		<AppLayout isHeaderVisible={!isMobile} title={t(tKey('titles.appointments'))}>
			{showClientModal && (
				<Modal isFullHeight width="w-[900px]" showCrossIcon={false} noPadding>
					<div className="sticky max-md:grid max-md:grid-flow-col max-md:auto-cols-auto md:flex items-center md:justify-between top-0 z-10 bg-white max-md:p-5 md:px-8 py-4 border-b border-border">
						<ChevronLeftIcon
							onClick={() => setShowClientModal(false)}
							className="w-3 h-3 md:hidden cursor-pointer"
						/>
						<h1 className="text-primary text-[20px] max-md:text-center whitespace-nowrap max-md:text-sm font-domine font-bold">
							{t('clients.create.titles.addClient')}
						</h1>
						<div className="md:hidden" />
						<XMarkIcon
							onClick={() => setShowClientModal(false)}
							className="text-primary max-md:hidden bg-white h-6 w-6 cursor-pointer"
						/>
					</div>
					<CreateClient
						onCreation={() => {
							setForceRefresh(prev => !prev)
							setShowModal(false)
						}}
						setCreatedClient={setCreatedClient}
						onCancel={() => setShowModal(false)}
					/>
				</Modal>
			)}
			{showModal && (
				<Modal isFullHeight width="w-[900px]" showCrossIcon={false} noPadding>
					<div className="sticky max-md:grid max-md:grid-flow-col max-md:auto-cols-auto md:flex items-center md:justify-between top-0 z-10 bg-white max-md:p-5 md:px-8 py-4 border-b border-border">
						<ChevronLeftIcon
							onClick={() => setShowModal(false)}
							className="w-3 h-3 md:hidden cursor-pointer"
						/>
						<h1 className="text-primary max-md:text-center whitespace-nowrap max-md:text-sm font-domine font-bold">
							{t(tKey('create.titles.createAppointment'))}
						</h1>
						<div className="md:hidden" />
						<XMarkIcon
							onClick={() => setShowModal(false)}
							className="text-primary max-md:hidden bg-white h-6 w-6 cursor-pointer"
						/>
					</div>
					<CreateAppointment
						onConfirm={() => {
							setForceRefresh(prev => !prev)
							setShowModal(false)
						}}
						onAddClient={() => setShowClientModal(true)}
						createdClient={createdClient}
						showInfo={false}
						onCancel={() => setShowModal(false)}
					/>
				</Modal>
			)}
			{showEmployeeModal?.id && (
				<Modal isFullHeight width="w-[700px]" showCrossIcon={false} noPadding>
					<div className="sticky max-md:grid max-md:grid-flow-col max-md:auto-cols-auto md:flex items-center md:justify-between top-0 z-10 bg-white max-md:p-5 md:px-8 py-4 border-b border-border">
						<ChevronLeftIcon
							onClick={() => setShowEmployeeModal(undefined)}
							className="w-3 h-3 md:hidden cursor-pointer"
						/>
						<h1 className="text-primary max-md:text-center whitespace-nowrap max-md:text-sm font-domine font-bold">
							{t(tKey('titles.selectEmployee'))}
						</h1>
						<div className="md:hidden" />
						<XMarkIcon
							onClick={() => setShowEmployeeModal(undefined)}
							className="text-primary max-md:hidden bg-white h-6 w-6 cursor-pointer"
						/>
					</div>
					<div className="max-md:px-5 md:px-8 py-6">
						{isLoading ? (
							<div className="flex items-center mt-4 justify-center gap-x-2">
								<Spinner />
								<p>{t(tKey('labels.pleaseWait'))}</p>
							</div>
						) : availableEmployees && availableEmployees?.length > 0 ? (
							<Combobox
								value={selectedEmployee}
								onChange={(value: string) => setSelectedEmployee(value)}>
								<div className="relative mt-1">
									<div className="relative cursor-default w-full overflow-hidden bg-white text-left border-none focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
										<Combobox.Input
											placeholder={t(tKey('placeholders.employee'))}
											className="border border-border pr-7 w-full py-2 md:py-3 rounded focus:border-secondary text-sm leading-5 text-gray-900 focus:ring-0"
											displayValue={option =>
												option
													? availableEmployees?.find(employee => employee._id === selectedEmployee)
															?.fname +
														' ' +
														availableEmployees?.find(employee => employee._id === selectedEmployee)
															?.lname
													: ''
											}
											onChange={event => setEmployeeQuery(event.target.value)}
										/>

										<Combobox.Button className="absolute inset-y-0 flex items-center right-0 pr-2">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="20"
												height="20"
												viewBox="0 0 20 20"
												fill="none">
												<path
													d="M9.99988 12.2178C9.89945 12.2178 9.80597 12.2018 9.71944 12.1697C9.6329 12.1377 9.55064 12.0826 9.47265 12.0047L5.72746 8.25947C5.61207 8.14409 5.55305 7.99906 5.55038 7.82438C5.5477 7.6497 5.60673 7.502 5.72746 7.38128C5.84819 7.26056 5.99455 7.2002 6.16655 7.2002C6.33855 7.2002 6.48491 7.26056 6.60563 7.38128L9.99988 10.7755L13.3941 7.38128C13.5095 7.2659 13.6545 7.20687 13.8292 7.20419C14.0039 7.20153 14.1516 7.26056 14.2723 7.38128C14.393 7.502 14.4534 7.64837 14.4534 7.82038C14.4534 7.99238 14.393 8.13874 14.2723 8.25947L10.5271 12.0047C10.4491 12.0826 10.3669 12.1377 10.2803 12.1697C10.1938 12.2018 10.1003 12.2178 9.99988 12.2178Z"
													fill="#123258"
												/>
											</svg>
										</Combobox.Button>
									</div>
									<Transition
										as={Fragment}
										leave="transition ease-in duration-100"
										leaveFrom="opacity-100"
										leaveTo="opacity-0">
										<Combobox.Options className="absolute mt-1 z-50 max-h-60 min-w-max w-full overflow-auto rounded-md bg-white pt-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
											{Array.isArray(availableEmployees) &&
												filteredEmployeeOptions?.map((option, index) => (
													<Combobox.Option
														key={index}
														className={({ active }) =>
															`relative cursor-default select-none py-0.5 ${
																active ? 'bg-[#13BAB40F] text-white' : 'text-gray-900'
															}`
														}
														value={option._id}>
														{({ selected, active }) => (
															<span className="flex gap-x-3 px-4 md:px-6 py-1.5 items-center">
																<span className="flex-col group items-start text-primary font-semibold w-full overflow-hidden text-ellipsis text-xs md:text-sm whitespace-nowrap grow hover:bg-[#13BAB40F] flex text-left rounded-md">
																	{option.fname + ' ' + option.lname}

																	<span className="text-tertiary text-xs">
																		{option.city && option.zip
																			? `${option.city} - ${option.zip}`
																			: ''}
																	</span>
																</span>
															</span>
														)}
													</Combobox.Option>
												))}
										</Combobox.Options>
									</Transition>
								</div>
							</Combobox>
						) : (
							<div className="flex items-center gap-x-2">
								<XCircleIcon className="fill-red-500 size-8" />
								<p>{t(tKey('labels.noEmployees'))}</p>
							</div>
						)}
						<div className="flex justify-end gap-x-6 py-6">
							<button
								className="max-md:text-sm"
								type="button"
								onClick={() => setShowEmployeeModal(undefined)}>
								{t(tKey('buttons.cancel'))}
							</button>
							<Button
								disabled={!selectedEmployee}
								onClick={assignEmployee}
								className={clsx('px-8 py-3 max-md:text-sm max-md:py-[9px]', {
									'bg-none !bg-gray-400': !selectedEmployee
								})}>
								{assigningEmployee ? (
									<div className="flex items-center justify-center gap-x-5">
										<Spinner />
										<span className="animate-pulse whitespace-nowrap">
											{t(tKey('labels.pleaseWait'))}
										</span>
									</div>
								) : (
									t(tKey('buttons.assign'))
								)}
							</Button>
						</div>
					</div>
				</Modal>
			)}
			<div className="p-5 bg-background min-h-screen md:p-6 flex flex-col gap-y-5 md:gap-y-8">
				<div className="flex items-center justify-between md:hidden">
					<h1 className="text-primary font-domine font-bold">{t(tKey('titles.appointments'))}</h1>
					<div className="flex gap-x-2.5 items-center">
						<div
							style={{ boxShadow: '0px 6px 24px 0px rgba(18, 50, 88, 0.08)' }}
							onClick={() => {
								if (isSearchVisible) {
									setSearchText('')
									return setIsSearchVisible(false)
								}
								setIsSearchVisible(true)
							}}
							className={clsx(
								'rounded-full border bg-white border-[#D3E3F1] p-[5px] cursor-pointer',
								{
									'!bg-[#1232584D]': isSearchVisible
								}
							)}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none">
								<g clipPath="url(#clip0_2734_2383)">
									<path
										d="M12.9162 11.6672H12.2579L12.0245 11.4422C13.0245 10.2755 13.5412 8.68386 13.2579 6.99219C12.8662 4.67552 10.9329 2.82552 8.59954 2.54219C5.07454 2.10885 2.10788 5.07552 2.54121 8.60052C2.82454 10.9339 4.67455 12.8672 6.99121 13.2589C8.68288 13.5422 10.2745 13.0255 11.4412 12.0255L11.6662 12.2589V12.9172L15.2079 16.4589C15.5495 16.8005 16.1079 16.8005 16.4495 16.4589C16.7912 16.1172 16.7912 15.5589 16.4495 15.2172L12.9162 11.6672ZM7.91621 11.6672C5.84121 11.6672 4.16621 9.99219 4.16621 7.91719C4.16621 5.84219 5.84121 4.16719 7.91621 4.16719C9.99121 4.16719 11.6662 5.84219 11.6662 7.91719C11.6662 9.99219 9.99121 11.6672 7.91621 11.6672Z"
										fill="#123258"
									/>
								</g>
								<defs>
									<clipPath id="clip0_2734_2383">
										<rect width="20" height="20" fill="white" />
									</clipPath>
								</defs>
							</svg>
						</div>
						<div
							style={{ boxShadow: '0px 6px 24px 0px rgba(18, 50, 88, 0.08)' }}
							onClick={() => setIsMobileFilterOpen(true)}
							className={clsx(
								'rounded-full border bg-white border-[#D3E3F1] p-[5px] cursor-pointer',
								{
									'!bg-[#1232584D]':
										selectedDate ||
										selectedCategory !== 'allCategories' ||
										selectedStatus !== 'allStatus'
								}
							)}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none">
								<path
									d="M4.16699 17.5V7.5M15.8337 2.5V5.83333V2.5ZM15.8337 17.5V9.16667V17.5ZM10.0003 2.5V12.5V2.5ZM10.0003 17.5V15.8333V17.5ZM4.16699 2.5V4.16667V2.5Z"
									stroke="#123258"
									strokeWidth="1.2"
									strokeLinecap="round"
								/>
								<path
									d="M14.1667 7.49968C14.1667 8.42015 14.9129 9.16634 15.8333 9.16634C16.7538 9.16634 17.5 8.42015 17.5 7.49968C17.5 6.5792 16.7538 5.83301 15.8333 5.83301C14.9129 5.83301 14.1667 6.5792 14.1667 7.49968Z"
									stroke="#123258"
									strokeWidth="1.2"
									strokeLinecap="round"
								/>
								<path
									d="M8.33366 14.1667C8.33366 15.0871 9.07985 15.8333 10.0003 15.8333C10.9208 15.8333 11.667 15.0871 11.667 14.1667C11.667 13.2462 10.9208 12.5 10.0003 12.5C9.07985 12.5 8.33366 13.2462 8.33366 14.1667Z"
									stroke="#123258"
									strokeWidth="1.2"
									strokeLinecap="round"
								/>
								<path
									d="M2.49967 5.83366C2.49967 6.75413 3.24587 7.50033 4.16634 7.50033C5.08682 7.50033 5.83301 6.75413 5.83301 5.83366C5.83301 4.91318 5.08682 4.16699 4.16634 4.16699C3.24587 4.16699 2.49967 4.91318 2.49967 5.83366Z"
									stroke="#123258"
									strokeWidth="1.2"
									strokeLinecap="round"
								/>
							</svg>
						</div>
						<div
							style={{ boxShadow: '0px 6px 24px 0px rgba(18, 50, 88, 0.08)' }}
							onClick={() => setShowModal(true)}
							className="rounded-full border bg-white border-[#D3E3F1] p-[5px] cursor-pointer">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none">
								<path
									d="M9.37492 10.625H5.20825C5.03117 10.625 4.88273 10.5651 4.76294 10.4452C4.64315 10.3254 4.58325 10.1769 4.58325 9.99975C4.58325 9.82258 4.64315 9.67417 4.76294 9.55452C4.88273 9.43487 5.03117 9.37504 5.20825 9.37504H9.37492V5.20837C9.37492 5.03129 9.43484 4.88285 9.55467 4.76306C9.67452 4.64327 9.82302 4.58337 10.0002 4.58337C10.1773 4.58337 10.3257 4.64327 10.4454 4.76306C10.5651 4.88285 10.6249 5.03129 10.6249 5.20837V9.37504H14.7915C14.9686 9.37504 15.1171 9.43496 15.2369 9.55479C15.3566 9.67464 15.4165 9.82314 15.4165 10.0003C15.4165 10.1775 15.3566 10.3259 15.2369 10.4455C15.1171 10.5652 14.9686 10.625 14.7915 10.625H10.6249V14.7917C10.6249 14.9688 10.565 15.1172 10.4451 15.237C10.3253 15.3568 10.1768 15.4167 9.99963 15.4167C9.82246 15.4167 9.67405 15.3568 9.5544 15.237C9.43474 15.1172 9.37492 14.9688 9.37492 14.7917V10.625Z"
									fill="#123258"
								/>
							</svg>
						</div>
					</div>
				</div>
				<div className="flex max-md:hidden justify-between items-center">
					<div className="relative flex items-center justify-end">
						<input
							style={{ boxShadow: '0px 6px 24px 0px rgba(18, 50, 88, 0.08)' }}
							type="text"
							placeholder={t(tKey('placeholders.keyword'))}
							value={searchText}
							onChange={e => setSearchText(e.target.value)}
							className="px-4 py-2 w-[322px] placeholder:text-[#7F9AB2] text-primary focus:border-primary-light border border-border rounded-full"
						/>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="25"
							viewBox="0 0 24 25"
							fill="none"
							className="absolute -translate-x-3">
							<g clipPath="url(#clip0_2878_8260)">
								<path
									d="M15.4996 14.5006H14.7096L14.4296 14.2306C15.6296 12.8306 16.2496 10.9206 15.9096 8.89063C15.4396 6.11063 13.1196 3.89063 10.3196 3.55063C6.08965 3.03063 2.52965 6.59063 3.04965 10.8206C3.38965 13.6206 5.60965 15.9406 8.38965 16.4106C10.4196 16.7506 12.3296 16.1306 13.7296 14.9306L13.9996 15.2106V16.0006L18.2496 20.2506C18.6596 20.6606 19.3296 20.6606 19.7396 20.2506C20.1496 19.8406 20.1496 19.1706 19.7396 18.7606L15.4996 14.5006ZM9.49965 14.5006C7.00965 14.5006 4.99965 12.4906 4.99965 10.0006C4.99965 7.51063 7.00965 5.50063 9.49965 5.50063C11.9896 5.50063 13.9996 7.51063 13.9996 10.0006C13.9996 12.4906 11.9896 14.5006 9.49965 14.5006Z"
									fill="#123258"
								/>
							</g>
							<defs>
								<clipPath id="clip0_2878_8260">
									<rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
								</clipPath>
							</defs>
						</svg>
					</div>
					<div className="flex gap-x-4 items-center">
						<InputDate
							placeholder={t(tKey('placeholders.allTime'))}
							value={selectedDate}
							onDateChange={(value: string) => setSelectedDate(value)}
							className="rounded-[6px] px-4 py-2.5"
						/>
						<MenuDropdown
							options={[
								'allCategories',
								...Object.values(companyCategories ?? {}).map(category => category.category_name)
							]}
							onChange={(value: string) => setSelectedCategory(value)}
							value={selectedCategory}
						/>
						<MenuDropdown
							options={['allStatus', ...Object.values(mappedAppointmentStatuses)]}
							onChange={(value: string) => setSelectedStatus(value)}
							value={selectedStatus}
						/>
						<button
							style={{ boxShadow: '0px 6px 24px 0px rgba(18, 50, 88, 0.08)' }}
							onClick={() => setShowModal(true)}
							className="pr-4 pl-3 py-2.5 flex items-center gap-x-2 rounded bg-primary-light text-white text-sm font-semibold">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none">
								<path
									d="M9.58333 10.4167H5V9.58333H9.58333V5H10.4167V9.58333H15V10.4167H10.4167V15H9.58333V10.4167Z"
									fill="white"
								/>
							</svg>
							<span className="whitespace-nowrap">{t(tKey('buttons.newAppointment'))}</span>
						</button>
					</div>
				</div>
				{isSearchVisible && (
					<div className="relative lg:hidden flex items-center">
						<input
							style={{ boxShadow: '0px 6px 24px 0px rgba(18, 50, 88, 0.08)' }}
							type="text"
							placeholder={t(tKey('placeholders.keyword'))}
							autoFocus
							className="rounded w-full placeholder:text-tertiary text-primary focus:outline-none border-border focus:ring-0 focus:border-primary-light px-4 py-[5px]"
							onChange={event => setSearchText(event.target.value)}
						/>
						<XMarkIcon
							onClick={() => {
								setSearchText('')
								setIsSearchVisible(false)
							}}
							className="absolute h-6 w-6 right-3 stroke-primary stroke-1"
						/>
					</div>
				)}
				<div className="md:hidden">
					<div
						onClick={() => {
							if (sorting?.isSorted && sorting.type === 'desc') return setSorting(undefined)
							if (!sorting?.isSorted) return setSorting({ isSorted: true, type: 'asc' })
							if (sorting.type === 'asc') return setSorting({ isSorted: true, type: 'desc' })
						}}
						className="px-6 py-3 cursor-pointer flex items-center justify-between rounded-tl-xl rounded-tr-xl bg-[#C6C7F8] text-primary">
						<h3 className="font-domine text-sm font-bold">{t(tKey('tables.fullName'))}</h3>
						<div className="flex flex-col">
							<ChevronUpIcon
								className={clsx(
									'h-3 w-3 cursor-pointer',
									sorting?.isSorted && sorting.type === 'asc'
										? 'stroke-2 text-primary'
										: 'text-gray-400'
								)}
							/>
							<ChevronDownIcon
								className={clsx(
									'h-3 w-3 cursor-pointer',
									sorting?.isSorted && sorting.type === 'desc'
										? 'stroke-2 text-primary'
										: 'text-gray-400'
								)}
							/>
						</div>
					</div>
					<Pagination
						renderComponent={renderMobileAppointmentsTable}
						items={filteredAppointments}
					/>
				</div>
				<Table
					data={filteredAppointments}
					shouldRedirect="appointment"
					columns={columns}
					hasActionColumn
					className="max-md:hidden table-auto"
				/>
			</div>
		</AppLayout>
	)
}
