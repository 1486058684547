import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { CheckmarkIcon, DeleteIcon } from 'assets/icons'
import { AppLayout } from 'components/app/layout'
import useIsMobile from 'hooks/useIsMobile'
import companyService from 'services/company-service'
import { getTKey } from 'utils/language'

export const SmsStatus = () => {
	const isMobile = useIsMobile()
	const [searchParams, _setSearchParams] = useSearchParams()
	const [smsStatus, setSmsStatus] = useState<{ success: boolean; message: string }>()
	const { t } = useTranslation()

	const tKey = getTKey('licenses')

	useEffect(() => {
		if (searchParams.get('session_id')) {
			if (searchParams.get('session_id') !== '0') {
				companyService
					.verifySms(searchParams.get('session_id') as string)
					.then(res => setSmsStatus({ success: true, message: res.data.message }))
					.catch(err => setSmsStatus({ success: false, message: err.response.data.message }))
			} else if (searchParams.get('session_id') === '0') {
				setSmsStatus({ success: false, message: t(tKey('errors.somethingWentWrong')) })
			}
		}
	}, [searchParams.get('session_id')])

	return (
		<AppLayout isHeaderVisible={!isMobile} title={t('companyProfile.titles.smsRecharge')}>
			<div className="lg:pt-14 pt-10 pb-24 max-lg:pb-10 flex flex-col gap-y-8 mx-4 lg:mx-24">
				{smsStatus?.success && (
					<div className="flex flex-col items-center gap-y-4">
						<img
							src={CheckmarkIcon}
							className="mx-auto h-8 w-8 md:h-14 md:w-14 mb-2 object-contain"
						/>
						<h3 className="capitalize max-md:text-center font-domine font-bold md:text-[18px]">
							{smsStatus.message}
						</h3>
					</div>
				)}
				{smsStatus?.success === false && (
					<div className="flex flex-col items-center gap-y-4">
						<img src={DeleteIcon} className="mx-auto h-8 md:h-14 w-8 md:w-14 mb-2 object-contain" />
						<h3 className="capitalize max-md:text-center font-domine font-bold md:text-[18px]">
							{smsStatus.message}
						</h3>
					</div>
				)}
			</div>
		</AppLayout>
	)
}
